import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const buttonDefaultVariants = {
  "variant": "primary",
  "size": "default"
}
const buttonCompoundVariants = [
  {
    "size": "small",
    "variant": "tertiary",
    "css": {
      "root": {
        "px": 0,
        "py": 0,
        "minH": "auto"
      }
    }
  },
  {
    "size": "default",
    "variant": "tertiary",
    "css": {
      "root": {
        "px": 0,
        "py": 0,
        "minH": "auto"
      }
    }
  },
  {
    "size": "small",
    "variant": "tertiary-negative",
    "css": {
      "root": {
        "px": 0,
        "py": 0,
        "minH": "auto"
      }
    }
  },
  {
    "size": "default",
    "variant": "tertiary-negative",
    "css": {
      "root": {
        "px": 0,
        "py": 0,
        "minH": "auto"
      }
    }
  },
  {
    "size": "default",
    "variant": "tertiary-negative",
    "css": {
      "root": {
        "px": 0,
        "py": 0,
        "minH": "auto"
      }
    }
  }
]

const buttonSlotNames = [
  [
    "root",
    "btn__root"
  ],
  [
    "label",
    "btn__label"
  ],
  [
    "icon",
    "btn__icon"
  ],
  [
    "loader",
    "btn__loader"
  ]
]
const buttonSlotFns = /* @__PURE__ */ buttonSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, buttonDefaultVariants, getSlotCompoundVariant(buttonCompoundVariants, slotName))])

const buttonFn = memo((props = {}) => {
  return Object.fromEntries(buttonSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const buttonVariantKeys = [
  "variant",
  "size",
  "isDisabled",
  "noIconTransform"
]

export const button = /* @__PURE__ */ Object.assign(buttonFn, {
  __recipe__: false,
  __name__: 'button',
  raw: (props) => props,
  variantKeys: buttonVariantKeys,
  variantMap: {
  "variant": [
    "primary",
    "secondary",
    "tertiary",
    "tertiary-negative",
    "icon",
    "error"
  ],
  "size": [
    "default",
    "small"
  ],
  "isDisabled": [
    "true"
  ],
  "noIconTransform": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, buttonVariantKeys)
  },
})