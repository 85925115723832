/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { useTranslation as useTranslationOrg } from 'react-i18next';

// import { Locale } from '@arpia-pt-frontends/lib/services/i18n';

// TODO fix this
// import type { PagePropsParams } from '@/services/page';
// type PagePropsParams = any;

// const isServerSide = typeof window === 'undefined';

// i18next
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(
//     resourcesToBackend((language: string, namespace: string) => {
//       console.log('INIT I18NEXT LIB');

//       return import(`./locales/${language}/${namespace}.json`);
//     })
//   )
//   // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with server side
//   .init({
//     ...getOptions(),
//     lng: undefined, // let detect the language on client side
//     detection: {
//       order: ['path', 'htmlTag', 'cookie', 'navigator']
//     },
//     preload: isServerSide ? LANGUAGES_AVAILABLE : []
//   });

export function useTranslation(ns: string = 'default', options: any = {} /* locale?: Locale */) {
  //  const params = useParams() as PagePropsParams;
  //  const lng = locale || params?.lng;

  // const [cookies, setCookie, removeCookie] = useCookies([LANGUAGES_COOKIE_NAME]);
  const ret = useTranslationOrg(ns, options);
  //  const { i18n } = ret;

  // if (isServerSide && lng && i18n.resolvedLanguage !== lng) {
  //   i18n.changeLanguage(lng);
  // } else {
  //   const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

  //   useEffect(() => {
  //     if (activeLng === i18n.resolvedLanguage) return;
  //     setActiveLng(i18n.resolvedLanguage);
  //   }, [activeLng, i18n.resolvedLanguage]);

  //   useEffect(() => {
  //     if (!lng || i18n.resolvedLanguage === lng) return;
  //     i18n.changeLanguage(lng);
  //   }, [lng, i18n]);

  //   useEffect(() => {
  //     if (cookies.i18next === lng) {
  //       return;
  //     }
  //     if (!window._env_?.languages || window._env_.languages.length > 1) {
  //       setCookie(LANGUAGES_COOKIE_NAME, lng, { path: '/' });
  //     } else {
  //       removeCookie(LANGUAGES_COOKIE_NAME, { path: '/' });
  //     }
  //   }, [lng, cookies.i18next, setCookie, removeCookie]);
  // }

  return ret;
}
