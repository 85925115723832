import { isServer } from '../../utils';

import { LANGUAGES_FALLBACK } from './i18n.settings';

export const getLanguages = () => {
  if (isServer()) {
    /**
     * TODO FIX THIS LOOKUP ON CMS LANGUAGES THEN
     */
    return [LANGUAGES_FALLBACK];
  }

  return window._env_?.languages || [LANGUAGES_FALLBACK];
};
